import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/Header"
import Layout from "../components/Layout"
import { btn, btnPrimary } from "../ds/button.module.css"
import { BlueArrowRight, WhiteArrowRight } from "../ds/Shapes"



const avatarStudioFeatures = [
  {
    id: 1,
    title:"Fun shareable <br/> content",
    description: "Create playful, shareable AI avatars that turn mundane event networking into a fun, memorable brand experience",
    image:<StaticImage
    src="../images/avatar-studio/share-fun.png"
    alt="Fun shareable content"
    className="w-full h-full object-contain rounded-2xl"
  />
  },
  {
    id: 2,
    title:"A branded <br/> keepsake",
    description: "Embed your event's brand into every quirky, personalized avatar that keep your brand top-of-mind long after the event ends.",
    image:<StaticImage
    src="../images/avatar-studio/branded-keepsake.png"
    alt="Branded Keepsake"
    className="w-full h-full object-contain rounded-2xl"
  />
  },
  {
    id: 2,
    title:"Fuel viral <br/> conversations",
    description: "Encourage social interaction with out-of-this-world avatars that your attendees won’t hesitate to flaunt.",
    image:<StaticImage
    src="../images/avatar-studio/fuel-viral.png"
    alt="Fuel Viral"
    className="w-full h-full object-contain rounded-2xl"
  />
  },
]

export default function index() {
  return (
    <Layout
      title="Add a playful twist with AI Avatars"
      description="Let your attendees transform into their most fun, unforgettable avatars!"
    >
      <div className="">
        <div className="" style={{ backgroundColor: "#7251F4" }}>
          <Header isWhiteLogo />
        </div>

        <section className="md:py-28 relative bg-[#7251F4]">
          <div className="bg-gradient-to-t from-[#7251F4] to-transparent h-64 w-full absolute bottom-0 right-0 z-10"></div>

          <div className="px-5 md:px-0 mx-auto md:w-[1080px] flex items-center h-[500px]">
            <div className="md:w-[600px] text-left">
              <h1 className="text-3xl md:text-6xl font-bold mb-4 text-white leading-[1.3]">
                Add a playful twist with
                <span className="text-[#61F2C2]"> AI Avatars</span>
              </h1>
              <p className="text-white mb-5">
                Let your attendees transform into their most fun, unforgettable
                <span className="text-[#61F2C2]"> avatars!</span>
              </p>
              <Link
                to="/request-a-demo/"
                className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group`}
              >
                <span className="mr-4 ml-2">Get Started</span>
                <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
              </Link>
            </div>
            <div className="w-11/12 md:w-6/12 absolute right-10 bottom-0">
              <StaticImage src="../images/ai_avatar_hero.png" alt="AI Avatar" />
            </div>
          </div>
        </section>

        <section className="md:pt-10 pt-10 md:pt-0 md:h-[500px] flex items-center">
          <div className="px-5 md:px-0 mx-auto md:w-[1080px] text-center">
            <h1 className="text-2xl md:text-5xl font-bold mb-8 text-[#7251F4]">From mundane to <br/> memorable, instantly!</h1>
            <p className="text-black mb-5 leading-[1.3] w-full md:w-[52%] mx-auto text-lg md:text-xl">Whether it's a space cowboy or warrior princess, these AI-powered avatars turn passive attendees into engaged brand ambassadors—effortlessly.
            </p>
            <Link
              to="/request-a-demo/"
              className={`${btn} ${btnPrimary} text-xs mt-5 mb-10 md:mb-0 relative w-max group`}
            >
              Get Started
            </Link>
           </div>
        </section>

        <section className="md:mb-20 p-24 px-10 md:px-[250px]" style={{ backgroundColor: "#E3F2ED" }}>
          {avatarStudioFeatures.map((item, idx) => (
            <div
              key={idx}
              className={`flex flex-col md:flex-row items-center border border-solid border-[#cbbeff] p-6 rounded-3xl md:h-[355px] overflow-hidden mb-4 
              ${idx % 2 !== 0 ? "md:flex-row-reverse" : ""}`}
            >
              <div className={`w-full md:w-[350px] ${idx % 2 !== 0 ? "md:ml-8" : "md:mr-8"}`}>
                <h3 className="mb-4 md:mb-6 font-bold text-2xl md:text-3xl text-[#7251F4]" dangerouslySetInnerHTML={{ __html: item.title }}></h3>
                <p className="text-base md:text-lg mb-6 md:mb-0">{item.description}</p>
                <Link
                  to="/request-a-demo/"
                  className={`w-full md:w-max text-base flex justify-center items-center text-[#284cf8] border border-solid border-[#284cf8] rounded-lg mt-5 mb-10 md:mb-0 relative group !py-2 px-4`}
                >
                    <span className="mr-4 ml-2">Get Started</span>
                    <BlueArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
                </Link>
              </div>
              <div className="w-full md:w-2/3 h-full">{item.image}</div>
            </div>
          ))}
        </section>

        <section className="md:my-20">
          <div className="px-5 md:px-0 mx-auto flex py-10 md:items-center flex-wrap md:w-[1080px]">
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
              <h4
                style={{ color: "#FF6550" }}
                className="text-5xl font-bold my-5"
              >
                3x
              </h4>
              <p className="w-full mx-auto text-center">
              more attendee engagement than traditional activations
              </p>
            </div>
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center mx-auto">
              <h4
                style={{ color: "#FF6550" }}
                className="text-5xl font-bold my-8 md:my-5 "
              >
                50%
              </h4>
              <p className="w-full mx-auto text-center">
              attendees share their avatars on social media
              </p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
              <h4
                style={{ color: "#FF6550" }}
                className="text-5xl font-bold my-5"
              >
                2x
              </h4>
              <p className="w-full mx-auto text-center">
              organizers say it made their event more memorable
              </p>
            </div>
          </div>
        </section>

        <div className="rounded-2xl xl:w-1/2 w-11/12 mt-20 mx-auto py-8 px-8 mb-10 flex md:items-center items-start md:flex-row flex-col md:justify-between justify-start" style={{ backgroundColor: "#7251F4" }}>
          <div className="md:mr-6 mb-4 md:mb-0">
            <h3 className="text-2xl font-extrabold text-white">Ditch the boring. bring on the fun!</h3>
            <p className="text-white mt-2 md:mb-5 text-lg">Turn your event into an adventure.</p>
            <Link
            to="/request-a-demo/"
            className={`${btn} ${btnPrimary} text-xs mb-10 md:mb-0 relative md:w-max group md:mt-0 mt-10 mb-0 !w-full`}
          >
            SEE HOW IT WORKS
          </Link>
          </div>
          <div className="w-fullmd:w-1/2">
              <StaticImage src="../images/avatar-studio/avatar-battle.png" alt="AI Avatar" />
            </div>
        </div>
      </div>
    </Layout>
  )
}
